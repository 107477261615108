// import React, { useEffect, useState, useMemo, useContext } from 'react';

// import {UserContext} from '../App';

// import {
//   collection,
//   onSnapshot,
//   orderBy,
//   query,
//   doc, 
//   getDoc,
//   where,
//   documentId,
//   updateDoc
// } from 'firebase/firestore'
// import { database } from '../firebase/config';

// // Components
// import { useTranslation } from 'react-i18next';
// import { Card, CardComplex } from '../components/Card';
// import { SectionTitle } from '../components/SectionTitle';
// import { useFetchData } from '../hooks/useFetchData';
// import { useFetchMovies } from '../hooks/useFetchMovies';
// import { useFilterMovies } from '../hooks/useFilterMovies';
// import { AdBloc } from '../components/AdBloc';
// // import SearchBar, { search, searchBar, moviesFilter, setMoviesFilter } from '../components/SearchBar';
// /*
// const fetchUsersForFilms = async (films) => {
//   const filmsWithUser = await Promise.all(
//     films.map(async (film) => {
//       const userRef = doc(database, 'users', film.user); // Reference to the user document
//       const userDoc = await getDoc(userRef); // Fetch the user document

//       if (userDoc.exists()) {
//         return { ...film, user: { id: userDoc.id, ...userDoc.data() } };
//       } else {
//         console.log(`User with ID ${film.user} does not exist.`);
//         return film;
//       }
//     })
//   );

//   return filmsWithUser;
// };
// */


// const Home = ({ search }) => {
//   //const { documents: competitions } = useFetchData('competitions'); //documents nelkul is szar

//   const { t, i18n } = useTranslation();
//   const [adUrl, setAdUrl] = useState('')
//   const {user} = useContext(UserContext)

//   //const [randomMovie, setRandomMovie] = useState(null);

//   const currentDate = useMemo(() => new Date(), []);
//   const formattedDate = currentDate.toLocaleDateString();
//   //const [currentCompetition, setCurrentCompetition] = useState(null); // Use null instead of 0 for a more meaningful initial state
//   //const [currentCompetitionFilms, setCurrentCompetitionFilms] = useState(null);
//   //const { filteredMovies: moviesFilter } = useFilterMovies(currentCompetitionFilms, search)

  
//   //const [collected, setCollected] = useState(0);
//   /*
//   useEffect(() => {
//     if (competitions && competitions.length > 0) {
//       const findCurrentCompetition = () => {
//         const currentCompetition = competitions.find(competition => {
//           const startDate = competition.startDate.toDate();
//           const endDate = competition.endDate.toDate();
//           return startDate <= currentDate && endDate >= currentDate;
//         }
//         );
//         setCurrentCompetition(currentCompetition);
//       };
//       findCurrentCompetition();
//     }
//   }, [competitions, currentDate]);

//   useEffect(() => {
//     if (currentCompetition) {
//       const q = query(
//         collection(database, 'films'),
//         where(documentId(), 'in', currentCompetition.films)
//       );
//       //filmek adatainak lekerese
//       const unsubscribe = onSnapshot(q, async (querySnapshot) => {
//         const films = [];
//         querySnapshot.forEach((doc) => {
//           films.push({ id: doc.id, ...doc.data() });
//         });

//         //összeegyűlt pénz
//         setCollected(0);
//         films.forEach((film) => {
//           setCollected(prevCollected => prevCollected + film.collected);
//         });

//         currentCompetition.collected=collected;      
//         const docRef = doc(database, "competitions", currentCompetition.id);
//         updateDoc(docRef, currentCompetition);
        
//         //filmek feltöltőinek adatainak lekerese
//         const filmsWithUser = await fetchUsersForFilms(films);
//         // Update state with the fetched films and user data
//         setCurrentCompetitionFilms(filmsWithUser);
//       });

//       return () => unsubscribe();
//     }
//   }, [currentCompetition]);
//   */

//   /*
//   useEffect(() => {
//     if (currentCompetitionFilms && currentCompetitionFilms.length > 0) {
//       const generateRandomMovie = () => {
//         const randomMovie = currentCompetitionFilms[Math.floor(Math.random() * currentCompetitionFilms.length)];
//         setRandomMovie(randomMovie);
//       };

//       const adUrls = [
//         'https://www.youtube.com/embed/bWfI239Qf68?si=1i8C14OaddOsgcBq&amp;controls=0',
//         'https://www.youtube.com/embed/bSwYTU1f-oI?si=ulg8OWQ1G3e7XMWE&amp;controls=0'
//       ];
//       const url = adUrls[Math.floor(Math.random() * adUrls.length)];
//       setAdUrl(url);

//       generateRandomMovie();
//     }
//   }, [currentCompetitionFilms]);
//   */

//   return (
//     <div className="min-h-fit">
//       <div className="section max-w-screen-lg mx-auto flex flex-col gap-4">
//         <div>
//           {!search && (
//             <SectionTitle
//               title={t("home.methods")}
//             />
//           )}
//         </div>
//       </div>
//       {/*!user && (
        
//       )*/}
//       <div className="section flex flex-col items-center gap-4">
//       </div>
//     </div>
//   );
// };

// export default Home;

import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Router
import { Link } from "react-router-dom";

// Icons
import { AiFillStar } from "react-icons/ai";

// Components
import Comments from "../components/Comments";
import { database } from "../firebase/config";
import { useTranslation } from "react-i18next";
import { UserIcon, UserIconList } from "../components/UserIcon";

// Radix Imports
import { Item } from "@radix-ui/react-navigation-menu";

// Headless ui
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

const User = ({ user }) => {
  return (
    <>
      <div className="scale-90 flex flex-row flex-wrap justify-center items-center">
        <div className="lg:flex items-stretch bg-nbgreylight mx-auto lg:mx-0 px-5 sm:py-2 pt-2 pb-3 rounded-3xl w-full min-w-fit lg:max-w-5xl container">
          <div className="lg:flex-auto px-2 sm:px-6 py-5 sm:py-7 lg:p-10 sm:pb-5 lg:pb-10 base">
            <div className="titleCategories">
              <div className="inline-flex flex-start justify-center items-center gap-8 min-w-full title">
                <div className="flex-auto bg-nbgreenmain h-1"></div>
                <h2 className="font-black font-h2-title text-3xl text-nbgreendark leading-tight tracking-tight headingTitle">
                  Improvisational Scene Structure
                </h2>
                <div className="flex-auto bg-nbgreenmain h-1"></div>
              </div>
              <div className="inline-flex flex-start justify-center items-center gap-2 w-full categories">
                <h3 className="font-bold font-h3-subtitle text-base text-nbgreenmain tracking-tighter">
                  #screenwriting
                </h3>
                <div className="flex-auto bg-nbgreenmain max-w-2 h-0.5"></div>
                <h3 className="font-bold font-h3-subtitle text-base text-nbgreenmain tracking-tighter">
                  #story structure
                </h3>
              </div>
            </div>
            <div className="flex md:flex-row lg:flex-row flex-col justify-between items-center px-2 sm:px-6 py-5 sm:py-7 lg:p-10 sm:pb-5 lg:pb-10 creatorInfo">
              <div className="flex items-center my-2 snippetAuthors">
                <div className="relative m-2.5 snippetAuthor">
                  <img
                    className="rounded-full w-24 h-24 avatar"
                    src="https://firebasestorage.googleapis.com/v0/b/near-balkan-films.appspot.com/o/images%2FBizzerProfil.jpg?alt=media&token=df43469c-bba0-4df1-ad6e-697d0cf651b4"
                    alt=""
                  ></img>
                  <span className="display:inline-block bottom-0 left-17 absolute border-2 border-nbwhitemain rounded-full w-6 h-6 overflow-hidden flag">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGWqznwaynEDGBlgCMkbib4Rm1Wsgk0j_BtA&s"
                      alt=""
                      className="w-full h-5 object-cover"
                    />
                  </span>
                </div>
                <div className="flex flex-col">
                  <h4 className="font-bold font-button text-2xl text-nbgreendark">
                    I. M. Bizzer
                  </h4>
                  <p className="text-nbgreendark leading-4">Near-Balkan</p>
                </div>
              </div>
              <div className="flex flex-col justify-start mt-4 originalAuthors">
                <div className="authorsTitle">
                  <p className="bg-nbgreenlight px-3 py-2 rounded-md min-w-fit font-bold font-button text-lg text-nbgreendark leading-4">
                    Original Authors
                  </p>
                </div>
                <div className="p-1 authorNames">
                  <p className="text-nbgreendark leading-4">Nikolay Nikolaev</p>
                  <p className="text-nbgreendark leading-4">Svetlozar Nachev</p>
                </div>
              </div>
            </div>
            <div>
              <div className="gap-2 grid bg-nbgreenlight rounded-3xl max-h-full problemSolution">
                <div className="relative flex justify-center mx-20 problem">
                  <div className="-bottom-4 absolute flex justify-center bg-nbgreylight px-8 py-2 rounded-full w-14 min-w-full h-14">
                    <div className="font-a-anchor font-bold text-2xl text-nbgreydark">
                      DRAMATIC SITUATION ARE USUALLY FLAT
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center space-x-4 my-8 px-4 explanationArrow">
                  <div className="flex-auto bg-nbgreylight h-1 explanation"></div>
                  <div className="arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="13"
                      viewBox="0 0 17 13"
                      fill="none"
                    >
                      <path
                        d="M0.5 5V0L16.5 0V5L8.5 13L0.5 5Z"
                        fill="#E9EEF0"
                      />
                    </svg>
                  </div>
                  <div className="flex-auto bg-nbgreylight h-1 explanation"></div>
                </div>
                <div className="relative flex justify-center mx-20 problem">
                  <div className="-top-6 absolute flex justify-center bg-nbgreylight px-8 py-2 rounded-full w-14 min-w-full h-14">
                    <div className="font-a-anchor font-bold text-2xl text-nbgreydark">
                      WE’VE MADE A DRAMATIC SITUATION IN HALF A MINUTE
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scale-90 flex flex-row flex-wrap justify-center items-center">
        <div className="lg:flex items-stretch bg-nbgreylight mx-auto lg:mx-0 px-5 sm:py-2 pt-2 pb-3 rounded-3xl w-full min-w-fit lg:max-w-5xl container">
          <div className="lg:flex-auto px-2 sm:px-6 py-5 sm:py-7 lg:p-10 sm:pb-5 lg:pb-10 base">
            <div className="titleCategories">
              <div className="inline-flex flex-start justify-center items-center gap-8 w-full title">
                <div className="flex-auto bg-nbgreenmain h-1"></div>
                <h2 className="font-black font-h2-title text-3xl text-nbgreendark leading-tight tracking-tight headingTitle">
                  Improvisational Scene Structure
                </h2>
                <div className="flex-auto bg-nbgreenmain h-1"></div>
              </div>
              <div className="inline-flex flex-start justify-center items-center gap-2 w-full categories">
                <h3 className="font-bold font-h3-subtitle text-base text-nbgreenmain tracking-tighter">
                  #screenwriting
                </h3>
                <div className="flex-auto bg-nbgreenmain max-w-2 h-0.5"></div>
                <h3 className="font-bold font-h3-subtitle text-base text-nbgreenmain tracking-tighter">
                  #story structure
                </h3>
              </div>
            </div>
            <div className="flex md:flex-row lg:flex-row flex-col justify-between items-center px-2 sm:px-6 py-5 sm:py-7 lg:p-10 sm:pb-5 lg:pb-10 creatorInfo">
              <div className="flex items-center my-2 snippetAuthors">
                <div className="relative m-2.5 snippetAuthor">
                  <img
                    className="rounded-full w-24 h-24 avatar"
                    src="https://firebasestorage.googleapis.com/v0/b/near-balkan-films.appspot.com/o/images%2FBizzerProfil.jpg?alt=media&token=df43469c-bba0-4df1-ad6e-697d0cf651b4"
                    alt=""
                  ></img>
                  <span className="display:inline-block bottom-0 left-17 absolute border-2 border-nbwhitemain rounded-full w-6 h-6 overflow-hidden flag">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGWqznwaynEDGBlgCMkbib4Rm1Wsgk0j_BtA&s"
                      alt=""
                      className="w-full h-5 object-cover"
                    />
                  </span>
                </div>
                <div className="flex flex-col">
                  <h4 className="font-bold font-button text-2xl text-nbgreendark">
                    I. M. Bizzer
                  </h4>
                  <p className="text-nbgreendark leading-4">Near-Balkan</p>
                </div>
              </div>
              <div className="flex flex-col justify-start mt-4 originalAuthors">
                <div className="authorsTitle">
                  <p className="bg-nbgreenlight px-3 py-2 rounded-md min-w-fit font-bold font-button text-lg text-nbgreendark leading-4">
                    Original Authors
                  </p>
                </div>
                <div className="p-1 authorNames">
                  <p className="text-nbgreendark leading-4">Nikolay Nikolaev</p>
                  <p className="text-nbgreendark leading-4">Svetlozar Nachev</p>
                </div>
              </div>
            </div>
            <div>
              <div className="gap-5 grid bg-nbgreenlight rounded-3xl problemSolution">
                <div className="relative flex justify-center mx-20 problem">
                  <div className="-bottom-4 absolute flex justify-center bg-nbgreylight px-8 py-2 rounded-full min-w-full">
                    <div className="font-a-anchor font-bold text-2xl text-nbgreydark">
                      DRAMATIC SITUATION ARE USUALLY FLAT
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-center px-16">
                  <div className="flex flex-col mx-28 my-8 explanationArrow">
                    <div className="flex flex-col gap-5 descriptionSpace1">
                      <div className="bg-nbgreylight rounded-xl one">
                        <div className="flex flex-row mx-2 my-2 pr-2">
                          <div className="flex justify-center bg-nbgreenlight rounded-full nubercomponent size-6">
                            <div className="font-a-anchor font-bold text-nbgreydark text-sm number">
                              1.
                            </div>
                          </div>
                          <div className="font-bold font-p-paragraph text-nbgreydark text-sm description">
                            Take three characters in a situation, two standing
                            in, one outside for now.
                          </div>
                        </div>
                      </div>
                      <div className="bg-nbgreylight rounded-xl two">
                        <div className="flex flex-row space-x-2.5 mx-2 my-2 px">
                          <div className="flex justify-center bg-nbgreenlight rounded-full nubercomponent size-6">
                            <div className="font-a-anchor font-bold text-nbgreydark text-sm number">
                              2.
                            </div>
                          </div>
                          <div className="font-bold font-p-paragraph text-nbgreydark text-sm description">
                            The first character should start any kind of
                            activity setting the scene/location/act.
                          </div>
                        </div>
                      </div>
                      <div className="bg-nbgreylight rounded-xl three">
                        <div className="flex flex-row space-x-2.5 mx-2 my-2">
                          <div className="flex justify-center bg-nbgreenlight rounded-full nubercomponent size-6">
                            <div className="font-a-anchor font-bold text-nbgreydark text-sm number">
                              3.
                            </div>
                          </div>
                          <div className="font-bold font-p-paragraph text-nbgreydark text-sm description">
                            The second character should then interact with the
                            first setting the relationship between the two of
                            them.
                          </div>
                        </div>
                      </div>
                      <div className="bg-nbgreylight rounded-xl four">
                        <div className="flex flex-row space-x-2.5 mx-2 my-2 px">
                          <div className="flex justify-center bg-nbgreenlight rounded-full nubercomponent size-6">
                            <div className="font-a-anchor font-bold text-nbgreydark text-sm number">
                              4.
                            </div>
                          </div>
                          <div className="font-bold font-p-paragraph text-nbgreydark text-sm description">
                            Then again the first character should create a
                            problem (something physically manifested, best if
                            climactic).
                          </div>
                        </div>
                      </div>
                      <div className="bg-nbgreylight rounded-xl five">
                        <div className="flex flex-row space-x-2.5 mx-2 my-2">
                          <div className="flex justify-center bg-nbgreenlight rounded-full nubercomponent size-6">
                            <div className="font-a-anchor font-bold text-nbgreydark text-sm number">
                              5.
                            </div>
                          </div>
                          <div className="font-bold font-p-paragraph text-nbgreydark text-sm description">
                            The second character should react to the first’s
                            problem and should now make it bigger, that’s ought
                            to be resolved immediately (with ticking clocks and
                            pointing guns).
                          </div>
                        </div>
                      </div>
                      <div className="bg-nbgreylight rounded-xl six">
                        <div className="flex flex-row space-x-2.5 mx-2 my-2 px">
                          <div className="flex justify-center bg-nbgreenlight rounded-full nubercomponent size-6">
                            <div className="font-a-anchor font-bold text-nbgreydark text-sm number">
                              6.
                            </div>
                          </div>
                          <div className="font-bold font-p-paragraph text-nbgreydark text-sm description">
                            Take three characters in a situation, two standing
                            in, one outside for now.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="13"
                        viewBox="0 0 17 13"
                        fill="none"
                      >
                        <path
                          d="M0.5 5.12634V0.208496L16.5 0.208496V5.12634L8.5 12.9949L0.5 5.12634Z"
                          fill="#E9EEF0"
                        />
                      </svg>
                    </div>
                    <div className="border-2 border-nbgreyligth border-dashed h-full"></div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="18"
                        viewBox="0 0 17 18"
                        fill="none"
                      >
                        <path
                          d="M5.00556 17.7914H0V12.7411L3.47556 9.2608L0 5.78048V0.768066H5.04333L8.5 4.22947L11.9567 0.768066H17V5.78048L13.5244 9.2608L17 12.7411V17.7914H11.9944L8.5 14.2921L5.00556 17.7914Z"
                          fill="#E9EEF0"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="relative flex justify-center mx-20 problem">
                  <div className="-top-4 absolute flex justify-center bg-nbgreylight px-8 py-2 rounded-full min-w-full">
                    <div className="font-a-anchor font-bold text-2xl text-nbgreydark">
                      WE’VE MADE A DRAMATIC SITUATION IN HALF A MINUTE
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
