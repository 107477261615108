// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAuLPxgsmPlSJa3VaxJWLxSHMN-IckYWk4",
  authDomain: "near-balkan-films.firebaseapp.com",
  databaseURL: "https://near-balkan-films-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "near-balkan-films",
  storageBucket: "near-balkan-films.appspot.com",
  messagingSenderId: "903812505852",
  appId: "1:903812505852:web:6825a05cc308158ce5f779",
  measurementId: "G-KSH7ZWT1JC"
};

export const app = initializeApp(firebaseConfig);
//export const analytics = getAnalytics(app);
export const database = getFirestore(app);
export const storage = getStorage(app);